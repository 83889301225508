/* Modern Login Page Styling */

.login-page {
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-container {
  width: 100%;
  max-width: 1200px;
  height: 100vh;
  max-height: 700px;
  margin: 0 auto;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.login-row {
  display: flex;
  height: 100%;
}

/* Left Panel - Feature Showcase */
.login-left-panel {
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  color: white;
  position: relative;
  overflow: hidden;
}

.login-left-panel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwIEwxMDAgMTAwIE0xMDAgMCBMMCAxMDAiIHN0cm9rZT0icmdiYSgyNTUsMjU1LDI1NSwwLjEpIiBzdHJva2Utd2lkdGg9IjAuNSIvPjwvc3ZnPg==');
  opacity: 0.1;
}

.login-left-content {
  padding: 40px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.login-brand {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-brand-icon {
  font-size: 28px;
  margin-right: 12px;
}

.login-brand h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: white;
}

.login-features {
  margin-top: 30px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateX(5px);
}

.feature-icon {
  font-size: 20px;
  margin-right: 12px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
}

.feature-text h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: white;
}

.feature-text p {
  font-size: 13px;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.4;
}

/* Right Panel - Login Form */
.login-right-panel {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login-card {
  width: 100%;
  max-width: 360px;
  border: none;
  box-shadow: none;
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-logo {
  width: 150px;
  height: auto;
  margin-bottom: 16px;
}

.login-title {
  font-size: 24px !important;
  font-weight: 700 !important;
  margin-bottom: 4px !important;
  color: #1f2937 !important;
}

.login-subtitle {
  font-size: 14px !important;
  color: #6b7280 !important;
  margin-bottom: 0 !important;
}

.login-form {
  margin-top: 20px;
}

.login-label {
  font-weight: 500;
  color: #374151;
}

.login-input {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
}

.login-input:hover, .login-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.login-icon {
  color: #9ca3af;
}

.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.login-forgot-link {
  color: #1890ff;
  font-size: 13px;
  transition: color 0.3s ease;
}

.login-forgot-link:hover {
  color: #096dd9;
  text-decoration: underline;
}

.captcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  transform: scale(0.85);
  transform-origin: center;
}

.login-button {
  height: 40px;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border: none;
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
  transition: all 0.3s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(24, 144, 255, 0.4);
}

.login-divider {
  margin: 16px 0;
}

.login-footer {
  text-align: center;
}

.login-terms {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 8px;
}

.login-terms a {
  color: #1890ff;
  transition: color 0.3s ease;
}

.login-terms a:hover {
  color: #096dd9;
  text-decoration: underline;
}

.login-help {
  font-size: 12px;
  color: #9ca3af;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .login-container {
    box-shadow: none;
    border-radius: 0;
    max-height: 100vh;
    height: 100vh;
  }
  
  .login-right-panel {
    padding: 20px 16px;
    align-items: center;
  }
  
  .login-card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 20px;
  }
  
  .login-logo {
    width: 150px;
  }
  
  .login-title {
    font-size: 22px !important;
  }
  
  .login-subtitle {
    font-size: 13px !important;
  }
  
  .captcha-container {
    transform: scale(0.77);
  }
  
  .login-form .ant-form-item {
    margin-bottom: 12px;
  }
}

/* Animation for the login form */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-card {
  animation: fadeIn 0.5s ease-out;
}

.feature-item:nth-child(1) {
  animation: fadeIn 0.5s ease-out 0.1s forwards;
  opacity: 0;
}

.feature-item:nth-child(2) {
  animation: fadeIn 0.5s ease-out 0.2s forwards;
  opacity: 0;
}

.feature-item:nth-child(3) {
  animation: fadeIn 0.5s ease-out 0.3s forwards;
  opacity: 0;
}
  