@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* Custom scrollbar */
::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track{
  background-color: #f1f1f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb{
  background-color: #c1c1c1;
  border-radius: 10px;
  transition: all 0.3s ease;
}
::-webkit-scrollbar-thumb:hover{
  background-color: #a0a0a0;
}

/* Global styles */
body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

/* Responsive typography */
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 14px;
  }
}

/* Ant Design overrides for better responsiveness */
.ant-layout {
  background: #f5f7fa !important;
}

.ant-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.ant-btn {
  border-radius: 6px;
  transition: all 0.3s ease;
}

.ant-input, .ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-table {
  border-radius: 12px;
  overflow: hidden;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .ant-table-content {
    overflow-x: auto;
  }
  
  .ant-card-body {
    padding: 16px;
  }
  
  .ant-form-item {
    margin-bottom: 16px;
  }
}

/* Animations and transitions */
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Utility classes */
.shadow-hover {
  transition: all 0.3s ease;
}

.shadow-hover:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
}

/* Fix for mobile drawer menu */
.drawer-menu-container .ant-drawer-body {
  padding: 0;
}

/* Responsive container */
.responsive-container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .responsive-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .responsive-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .responsive-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .responsive-container {
    max-width: 1140px;
  }
}

/* Fix for profile picture positioning */
.ant-layout-header {
  padding: 0 16px !important;
  overflow: visible !important;
}

.ant-dropdown-trigger {
  position: relative;
  z-index: 30;
}

@media (min-width: 1200px) {
  .ant-layout-header {
    padding: 0 24px !important;
  }
}

/* Fix for clinic logo and name */
.ant-layout-header h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

@media (min-width: 992px) {
  .ant-layout-header h1 {
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .ant-layout-header h1 {
    max-width: 400px;
  }
}

/* Office logo in sidebar */
.ant-layout-sider .office-logo-container {
  padding: 12px;
  margin-top: auto;
  margin-bottom: 12px;
  text-align: center;
}

.ant-layout-sider .office-logo-container img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  transition: all 0.3s ease;
}

.ant-layout-sider .office-logo-container p {
  margin-top: 8px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Office logo in header */
.ant-layout-header .office-logo-header {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 30;
  width: auto;
}

.ant-layout-header .office-logo-header img {
  height: 32px;
  width: auto;
  margin-right: 12px;
  transition: all 0.3s ease;
  object-fit: contain;
}

.ant-layout-header .office-logo-header h1 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #333;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

@media (max-width: 768px) {
  .ant-layout-header .office-logo-header img {
    height: 28px;
  }
}

@media (min-width: 992px) {
  .ant-layout-header .office-logo-header h1 {
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  .ant-layout-header .office-logo-header h1 {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .ant-layout-header .office-logo-header img {
    height: 24px;
    margin-right: 8px;
  }
  
  .ant-layout-header .office-logo-header h1 {
    max-width: 100px;
    font-size: 14px;
  }
}

/* Responsive table styles for all tables */
.lab-results-container,
.lab-orders-container,
.medications-container {
  width: 100%;
  overflow: hidden;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 20px;
  border-radius: 8px;
}

.responsive-table .ant-table-thead > tr > th {
  white-space: nowrap;
  background-color: #f0f7ff;
}

.responsive-table .ant-table-tbody > tr > td {
  word-break: break-word;
}

.responsive-table .ant-table-cell-fix-left,
.responsive-table .ant-table-cell-fix-right {
  background-color: #fff;
  z-index: 2;
}

.responsive-table .ant-table-cell-fix-left::after,
.responsive-table .ant-table-cell-fix-right::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.05);
}

.responsive-table .ant-table-row:hover .ant-table-cell {
  background-color: #f5f7fa !important;
}

@media (max-width: 768px) {
  .responsive-table .ant-table {
    font-size: 12px;
  }
  
  .responsive-table .ant-table-thead > tr > th,
  .responsive-table .ant-table-tbody > tr > td {
    padding: 8px 4px;
  }
  
  .responsive-table .ant-btn-sm {
    font-size: 12px;
    padding: 0 8px;
    height: 24px;
  }
  
  .responsive-table .ant-table-pagination {
    margin: 12px 0;
  }
}

/* Modal styles for better mobile experience */
.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: 480px) {
  .ant-modal-content {
    padding: 12px;
  }
  
  .ant-modal-header {
    padding: 12px 12px 8px;
  }
  
  .ant-modal-body {
    padding: 12px;
  }
  
  .ant-modal-footer {
    padding: 8px 12px 12px;
  }
}

/* Vitals and Allergies Styling */
.vitals-container .vitals-tabs .ant-tabs-nav {
  margin-bottom: 20px;
}

.vitals-container .vitals-tabs .ant-tabs-tab {
  padding: 12px 16px;
  transition: all 0.3s ease;
}

.vitals-container .vitals-tabs .ant-tabs-tab-active {
  background-color: #f0f7ff;
  border-radius: 8px 8px 0 0;
}

.vitals-container .vitals-tabs .ant-tabs-ink-bar {
  background-color: #1890ff;
  height: 3px;
  border-radius: 3px;
}

.vitals-container .ant-card:hover {
  transform: translateY(-3px);
}

/* Allergy cards styling */
.ant-card.ant-card-bordered {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.ant-tag {
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ant-tag:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Allergy modal styling */
.allergy-modal .ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.allergy-modal .ant-modal-header {
  padding: 16px 24px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #f0f0f0;
}

.allergy-modal .ant-modal-body {
  padding: 24px;
}

.allergy-modal .ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
  font-weight: 500;
}

.allergy-modal .ant-alert {
  border-radius: 8px;
}

.allergy-modal .ant-alert-message {
  font-size: 16px;
  font-weight: 500;
}

/* Empty state styling */
.ant-empty {
  padding: 40px 0;
}

.ant-empty .ant-empty-description {
  color: #8c8c8c;
}

/* Loading spinner */
.ant-spin {
  color: #1890ff;
}

/* Tooltip styling */
.ant-tooltip {
  max-width: 300px;
}

.ant-tooltip-inner {
  border-radius: 4px;
  padding: 8px 12px;
}

/* VitalSigns Page Styling */
.vitals-page-container {
  padding: 1.5rem;
  background-color: #f8fafc;
  min-height: 100vh;
}

.welcome-section h1 {
  transition: all 0.3s ease;
}

.welcome-section h1:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.vitals-summary-section .ant-card {
  height: 100%;
  overflow: hidden;
}

.vitals-summary-section .ant-card:hover {
  animation: pulse 2s infinite;
}


.dashboard-card:hover {
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(24, 144, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0);
  }
}

.vitals-info-section {
  transition: all 0.3s ease;
}

.vitals-info-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Redesigned Vitals History and Allergies Cards */
.vitals-history-card, .allergies-card {
  transition: all 0.3s ease;
}

.vitals-history-card:hover, .allergies-card:hover {
  transform: translateY(-5px);
}

.vitals-history-card .historical-badge,
.allergies-card .allergies-badge {
  transition: all 0.3s ease;
}

.vitals-history-card:hover .historical-badge,
.allergies-card:hover .allergies-badge {
  transform: scale(1.05);
}

.vitals-history-card h1,
.allergies-card h1 {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments for the cards */
@media (max-width: 768px) {
  .vitals-history-card .flex,
  .allergies-card .flex {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .vitals-history-card .ml-auto,
  .allergies-card .ml-auto {
    margin-left: 0;
    margin-top: 12px;
  }
  
  .vitals-history-card h1,
  .allergies-card h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .vitals-history-card .w-12,
  .allergies-card .w-12 {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .vitals-history-card .text-2xl,
  .allergies-card .text-2xl {
    font-size: 1.25rem;
  }
}

/* Enhance tabs in RecordedVitals */
.vitals-tabs .ant-tabs-nav {
  margin-bottom: 1.5rem;
  background: white;
  padding: 0.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.vitals-tabs .ant-tabs-tab {
  padding: 0.75rem 1.25rem;
  transition: all 0.3s ease;
}

.vitals-tabs .ant-tabs-tab:hover {
  background-color: rgba(24, 144, 255, 0.05);
  border-radius: 0.5rem;
}

.vitals-tabs .ant-tabs-tab-active {
  background-color: rgba(24, 144, 255, 0.1);
  border-radius: 0.5rem;
}

.vitals-tabs .ant-tabs-ink-bar {
  height: 3px;
  border-radius: 3px;
  background: linear-gradient(90deg, #1890ff, #096dd9);
}

/* Enhance cards in both sections */
.vital-card, .allergy-card {
  transition: all 0.3s ease;
  overflow: hidden;
  border-radius: 1rem;
}

.vital-card:hover, .allergy-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Enhance tooltips */
.ant-tooltip {
  max-width: 300px;
}

.ant-tooltip-inner {
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
}

/* Enhance empty states */
.ant-empty {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.ant-empty:hover {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Enhance loading spinner */
.ant-spin {
  transition: all 0.3s ease;
}

.ant-spin:hover {
  transform: scale(1.1);
}

/* Vital Details Modal Styling */
.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.ant-modal-header {
  padding: 16px 24px;
  background-color: #f9fafb;
  border-bottom: 1px solid #f0f0f0;
}

.ant-modal-title {
  font-weight: 600;
  font-size: 18px;
}

.ant-modal-body {
  padding: 20px 24px;
}

.ant-modal-footer {
  padding: 12px 24px;
  border-top: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f9fafb;
  font-weight: 500;
}

.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: 1px solid #f0f0f0;
}

/* Vital card hover effect */
.vitals-summary-section .ant-card:hover {
  transform: translateY(-5px) scale(1.03);
  transition: all 0.3s ease;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

/* Tooltip styling */
.ant-tooltip-inner {
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 13px;
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}

/* Vitals Category Buttons */
.vitals-category-button {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.vitals-category-button:hover:not(:disabled) {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.vitals-category-button:active:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.12);
}

/* Enhanced Vitals Data Modal */
.vitals-data-modal .ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.vitals-data-modal .ant-modal-header {
  padding: 20px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecef;
}

.vitals-data-modal .ant-modal-title {
  line-height: 1.5;
}

.vitals-data-modal .ant-modal-body {
  padding: 24px;
}

.vitals-data-modal .ant-modal-footer {
  padding: 16px 24px;
  border-top: 1px solid #eaecef;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Table styling */
.vitals-data-table .ant-table-thead > tr > th {
  background-color: #f0f7ff;
  font-weight: 600;
  color: #1d4ed8;
  padding: 16px 12px;
  transition: background-color 0.2s;
}

.vitals-data-table .ant-table-thead > tr > th:hover {
  background-color: #e0f0ff;
}

.vitals-data-table .ant-table-tbody > tr > td {
  padding: 14px 12px;
  transition: all 0.2s;
}

.vitals-data-table .ant-table-tbody > tr:hover > td {
  background-color: #f0f7ff;
}

.vitals-data-table .ant-table-tbody > tr.bg-gray-50:hover > td {
  background-color: #e6f4ff;
}

.vitals-data-table .ant-pagination {
  margin-top: 20px;
}

.vitals-data-table .ant-table-tbody > tr {
  cursor: pointer;
}

/* Info and tip boxes */
.vitals-data-modal .bg-gray-50,
.vitals-data-modal .bg-blue-50 {
  transition: all 0.3s ease;
}

.vitals-data-modal .bg-gray-50:hover,
.vitals-data-modal .bg-blue-50:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

/* Empty state styling */
.vitals-data-modal .ant-empty {
  padding: 40px 0;
}

.vitals-data-modal .ant-empty .ant-empty-description {
  color: #6b7280;
  font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .vitals-data-modal {
    max-width: 95vw !important;
  }
  
  .vitals-data-modal .ant-modal-title {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .vitals-data-modal .ant-modal-title .w-10 {
    margin-bottom: 8px;
  }
}

/* Excel Export Button */
.export-excel-button {
  background-color: #ecfdf5 !important;
  border-color: #10b981 !important;
  color: #047857 !important;
  display: flex !important;
  align-items: center !important;
  transition: all 0.3s ease !important;
}

.export-excel-button:hover:not(:disabled) {
  background-color: #d1fae5 !important;
  border-color: #059669 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.export-excel-button:active:not(:disabled) {
  transform: translateY(0);
}

.export-excel-button:disabled {
  background-color: #f3f4f6 !important;
  border-color: #d1d5db !important;
  color: #9ca3af !important;
}

.export-excel-button svg {
  margin-right: 6px;
}

/* Add animation for success message */
.ant-message-success {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add loading indicator styles */
.ant-spin-dot-item {
  background-color: #10b981 !important;
}

/* Enhance the info box with the export message */
.vitals-data-modal .text-green-600 {
  display: inline-block;
  animation: pulse 2s infinite;
}

/* Enhance table for better data visualization */
.vitals-data-table .ant-table-cell {
  position: relative;
  overflow: hidden;
}

.vitals-data-table .ant-table-cell::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.05), transparent);
}




